import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export default function FamilyCard(props) {
  const {
    id,
    name,
    logo,
    image,
    setFilters,
    redirect,
    setSelectedFamily,
    family,
  } = props;
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    setFilters({ family: id });
    setSelectedFamily({ selectedFamily: family });
    navigate(redirect);
  }, [id]);

  return (
    <Card
      onClick={onClick}
      sx={{
        display: 'flex',
        height: 100,
        borderRadius: 6,
        width: 280,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #167536',
        background: '#fff',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box px={2} py={4}>
        <img
          height="100%"
          width={'100%'}
          src={
            'https://res.cloudinary.com/dtmbyah8w/image/upload/v1703012578/' +
            logo
          }
          alt={'logo'}
        />
      </Box>
    </Card>
  );
}
