import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { adminBase } from './routes/routes';

export const MUIWrapperContext = createContext();

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const emptyCache = createCache({
  key: 'meaningless-key',
});

export default function MUIWrapper({ children }) {
  const [mode, setMode] = useState('light');
  const [direction, setDirection] = useState('ltr');
  const location = useLocation();

  const muiWrapperUtils = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      changeDirection: () => {
        setDirection((prevDir) => (prevDir === 'ltr' ? 'rtl' : 'ltr'));
      },
    }),
    [],
  );

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  const theme = useMemo(
    () =>
      createTheme({
        ...(location.pathname.includes(adminBase)
          ? {
              components: {
                MuiCssBaseline: {
                  styleOverrides: {
                    body: { background: '#161c24' },
                  },
                },
              },
            }
          : {}),
        palette: {
          mode,
          direction,
          ...(mode === 'light'
            ? {
                // palette values for light mode
                text: {
                  primary: grey[900],
                  secondary: grey[800],
                },
                primary: { main: '#3c8dbc' },
                background: {
                  default: '#f9f9f9',
                  paper: '#f9f9f9',
                },
              }
            : {
                // palette values for dark mode
                background: {
                  default: '#101418',
                  paper: '#101418',
                },
                text: {
                  primary: '#fff',
                  secondary: grey[500],
                },
              }),
        },
      }),
    [mode, direction],
  );

  return (
    <CacheProvider value={direction === 'rtl' ? cacheRtl : emptyCache}>
      <MUIWrapperContext.Provider value={muiWrapperUtils}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </MUIWrapperContext.Provider>
    </CacheProvider>
  );
}
