import React from 'react';

const Weight = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_147_36)" filter="url(#filter0_d_147_36)">
        <path
          d="M27.8715 51.743C42.07 51.743 53.5802 40.2328 53.5802 26.0343C53.5802 11.8357 42.07 0.325562 27.8715 0.325562C13.673 0.325562 2.16278 11.8357 2.16278 26.0343C2.16278 40.2328 13.673 51.743 27.8715 51.743Z"
          fill="#FBBC3A"
        />
        <path
          d="M44.5462 35.2992L41.8488 20.1979C41.2865 17.075 38.5534 14.8031 35.3435 14.8031H31.5215C32.098 13.9942 32.4396 13.0025 32.4396 11.9404C32.4396 9.1902 30.1763 6.95349 27.3935 6.95349C24.6106 6.95349 22.3473 9.1902 22.3473 11.9404C22.3473 13.0025 22.6889 13.9942 23.2654 14.8031H19.4434C16.2335 14.8031 13.4933 17.075 12.9382 20.1979L10.2336 35.2992C9.89195 37.2054 10.4186 39.1467 11.6784 40.6308C12.9382 42.1149 14.7816 42.9659 16.7388 42.9659H38.0339C39.9911 42.9659 41.8345 42.1149 43.0943 40.6308C44.3612 39.1467 44.8879 37.2054 44.5462 35.2992ZM27.3935 9.07766C28.9877 9.07766 30.2902 10.3648 30.2902 11.9404C30.2902 13.5159 28.9877 14.8031 27.3935 14.8031C25.7992 14.8031 24.4967 13.5159 24.4967 11.9404C24.4967 10.3648 25.7921 9.07766 27.3935 9.07766ZM41.4573 39.2662C40.6103 40.265 39.3648 40.8418 38.041 40.8418H16.7388C15.415 40.8418 14.1766 40.265 13.3225 39.2662C12.4684 38.2674 12.1268 36.9521 12.3545 35.672L15.052 20.5707C15.4292 18.4606 17.2726 16.9343 19.4434 16.9343H35.3435C37.5072 16.9343 39.3577 18.4676 39.7349 20.5707L42.4324 35.672C42.6601 36.9521 42.3043 38.2674 41.4573 39.2662Z"
          fill="white"
        />
        <path
          d="M23.8561 28.8844L26.5536 26.1342C26.9664 25.7122 26.9522 25.037 26.5251 24.629C26.0981 24.2211 25.4148 24.2352 25.002 24.6572L21.9843 27.7379V25.3957C21.9843 24.8119 21.5003 24.3336 20.9096 24.3336C20.3188 24.3336 19.8348 24.8119 19.8348 25.3957V32.3802C19.8348 32.964 20.3188 33.4422 20.9096 33.4422C21.5003 33.4422 21.9843 32.964 21.9843 32.3802V30.0379L25.002 33.1187C25.2155 33.3367 25.4931 33.4422 25.7778 33.4422C26.0483 33.4422 26.3187 33.3438 26.5251 33.1398C26.9522 32.7318 26.9664 32.0566 26.5536 31.6346L23.8561 28.8844Z"
          fill="white"
        />
        <path
          d="M33.8702 28.1248H32.2545C31.6638 28.1248 31.1798 28.6031 31.1798 29.1869C31.1798 29.7707 31.6638 30.249 32.2545 30.249H32.7741C32.6745 30.8539 32.1407 31.3111 31.5001 31.3111C30.7884 31.3111 30.2047 30.7414 30.2047 30.031V27.731C30.2047 27.0276 30.7812 26.4508 31.5001 26.4508C31.8987 26.4508 32.2759 26.6337 32.5179 26.9432C32.8809 27.4074 33.557 27.4918 34.0267 27.1261C34.4965 26.7673 34.5819 26.0991 34.2118 25.6349C33.557 24.805 32.5606 24.3267 31.493 24.3267C29.5926 24.3267 28.0482 25.853 28.0482 27.731V30.031C28.0482 31.909 29.5926 33.4353 31.493 33.4353C33.3933 33.4353 34.9378 31.909 34.9378 30.031V29.1799C34.9449 28.5961 34.4609 28.1248 33.8702 28.1248Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_147_36"
          x="0.162781"
          y="0.325562"
          width="55.4174"
          height="55.4174"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_147_36"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_147_36"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_147_36">
          <rect
            width="51.4174"
            height="51.4174"
            fill="white"
            transform="translate(2.16278 0.325562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Weight;
