import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import DeleteModal from '../../components/DeleteModal';
import Header from '../../components/Header/Header';
import FamilyList from './List';
import useFamilies from './useCategories';

const FamilyListContainer = () => {
  const { data, isLoading, count, refetch, query, modalProps } = useFamilies();

  const setQuery = useCallback(
    (query) => {
      refetch(query);
    },
    [refetch],
  );

  return (
    <Box>
      <Header title={'Category List'} />
      <main>
        <FamilyList
          data={data}
          count={count}
          setQuery={setQuery}
          query={query}
          isLoading={isLoading}
        />
        {modalProps?.open ? <DeleteModal {...modalProps} /> : null}
      </main>
    </Box>
  );
};

export default FamilyListContainer;
