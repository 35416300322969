import { createContext } from 'react';

const GlobalFiltersContext = createContext({
  filters: {},
  setFilters: () => null,
  selectedFamily: {},
  setSelectedFamily: () => null,
});

export default GlobalFiltersContext;
