import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { urls } from '../../routes/routes';
import { getCategories } from '../Categories/api';
import { getProducts } from '../Products/api';
import { createFamily, deleteFamily, getFamilies } from './api';
import { formatFamiliesData } from './utils';

const useFamilies = () => {
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const [modalProps, setModalProps] = useState({ open: false });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: families, isLoading } = useQuery(
    ['families', query],
    () => getFamilies(query),
    {},
  );

  const {
    mutateAsync: create,
    isError: createError,
    isLoading: createLoading,
  } = useMutation((family) => createFamily(family));
  const {
    mutateAsync: remove,
    isError: deleteError,
    isLoading: deleteLoading,
  } = useMutation((id) => {
    deleteFamily(id);
    queryClient.invalidateQueries(['families', query]);
  });

  const handleCloseDelete = useCallback(() => {
    setModalProps({
      open: false,
      id: null,
      content: null,
      title: null,
      action: () => null,
    });
  }, []);

  const handleOpenDelete = useCallback(
    async (id) => {
      const products = await getProducts({ limit: 10, skip: 0, family: id });
      const categories = await getCategories({
        limit: 10,
        skip: 0,
        family: id,
      });

      setModalProps({
        open: true,
        id,
        content: `Upon deleting this family you will delete ${categories.data.count} linked categories and ${products.data.count} linked products`,
        title: `Delete`,
        action: async () => {
          await remove(id);
          refetch(query);
          handleCloseDelete();
        },
        actionText: 'Delete Family',
        handleClose: handleCloseDelete,
        actionLoading: deleteLoading,
      });
    },
    [setModalProps, remove, query],
  );

  const refetch = useCallback((query) => setQuery(query), [setQuery]);

  const actions = useMemo(
    () => [
      {
        id: nanoid(),
        name: 'Edit',
        onClick: (id) => navigate(`${urls.family.edit}/${id}`),
      },
      {
        id: nanoid(),
        name: 'Delete',
        onClick: async (id) => await handleOpenDelete(id),
      },
    ],
    [handleOpenDelete],
  );

  if (isLoading) {
    return {
      data: [],
      isLoading,
    };
  }

  const data = formatFamiliesData(families.data, actions);

  return {
    data,
    isLoading,
    count: families.data.count,
    refetch,
    query,
    create,
    createError,
    raw: families.data,
    modalProps,
  };
};

export default useFamilies;
