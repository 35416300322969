import { Box, Button } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';

const RHFForm = (props) => {
  const { onSubmit, children, defaultValues, submit } = props;
  const methods = useForm({ defaultValues });
  const location = useLocation();

  useEffect(() => {
    if (isEmpty(defaultValues)) {
      Object.keys(methods.watch()).map((key) => methods.setValue(key, ''));
    }
  }, [defaultValues, location.pathname]);

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children}
        <Box sx={{ width: 150, alignSelf: 'center', padding: 2 }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            disabled={methods.formState.isSubmitting}
            endIcon={
              methods.formState.isSubmitting ? (
                <LoadingIndicator color={'grey'} size={20} />
              ) : null
            }
            sx={{
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#c0c0c0',
              },
            }}
          >
            {submit}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

RHFForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  submit: PropTypes.string.isRequired,
};

RHFForm.defaultProps = {
  defaultValues: {},
};

export default RHFForm;
