export const CSV_DATA = {
  products: {
    headers: [
      [
        null,
        'productNameEn',
        'productNameAr',
        'productBarcode',
        'productExpDate',
        'productNetWeight',
        'productDrainedWeight',
        'productUnitsPerCarton',
        'productCartonPerPalate',
        'productNumber',
      ],
    ],
    data: [
      [
        null,
        'productNameEn',
        'productNameAr',
        'productBarcode',
        'productExpDate',
        'productNetWeight',
        'productDrainedWeight',
        'productUnitsPerCarton',
        'productCartonPerPalate',
        'productNumber',
      ],
      [
        null,
        'newTestProduct',
        'تست جديد بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ], //rows
      [
        null,
        'newTestProduct1',
        'تست جديد ١ بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ],
      [
        null,
        'newTestProduct2',
        'تست جديد ٢ بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ],
      [
        null,
        'newTestProduct3',
        'تست جديد ٣ بضاعة',
        1234,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1234,
      ],
    ],
  },
  categories: {
    headers: [
      [
        null,
        'categoryNameEn',
        'categoryNameAr',
        'productNameEn',
        'productNameAr',
        'productBarcode',
        'productExpDate',
        'productNetWeight',
        'productDrainedWeight',
        'productUnitsPerCarton',
        'productCartonPerPalate',
        'productNumber',
      ],
    ],

    data: [
      [
        null,
        'categoryNameEn',
        'categoryNameAr',
        'productNameEn',
        'productNameAr',
        'productBarcode',
        'productExpDate',
        'productNetWeight',
        'productDrainedWeight',
        'productUnitsPerCarton',
        'productCartonPerPalate',
        'productNumber',
      ], //headers
      [
        null,
        'newTestCategory',
        'تست جديد تصنيف',
        'newTestProduct',
        'تست جديد بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ], //rows
      [
        null,
        null,
        null,
        'newTestProduct1',
        'تست جديد ١ بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ],
      [
        null,
        null,
        null,
        'newTestProduct2',
        'تست جديد ٢ بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ],
      [
        null,
        'newTestCategory1',
        'تصنيف تست جديد ١',
        'newTestProduct3',
        'تست جديد ٣ بضاعة',
        1234,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1234,
      ],
    ],
  },
  all: {
    headers: [
      [
        null,
        'familyNameEn',
        'familyNameAr',
        'categoryNameEn',
        'categoryNameAr',
        'productNameEn',
        'productNameAr',
        'productBarcode',
        'peoductExpDate',
        'productNetWeight',
        'productDrainedWeight',
        'productUnitsPerCarton',
        'productCartonPerPalate',
        'productNumber',
      ],
    ],
    data: [
      [
        null,
        'familyNameEn',
        'familyNameAr',
        'categoryNameEn',
        'categoryNameAr',
        'productNameEn',
        'productNameAr',
        'productBarcode',
        'productExpDate',
        'productNetWeight',
        'productDrainedWeight',
        'productUnitsPerCarton',
        'productCartonPerPalate',
        'productNumber',
      ], //headers
      [
        null,
        'newTest',
        'تست جديد',
        'newTestCategory',
        'تست جديد تصنيف',
        'newTestProduct',
        'تست جديد بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ], //rows
      [
        null,
        null,
        null,
        null,
        null,
        'newTestProduct1',
        'تست جديد ١ بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ],
      [
        null,
        null,
        null,
        null,
        null,
        'newTestProduct2',
        'تست جديد ٢ بضاعة',
        123,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1233,
      ],
      [
        null,
        null,
        null,
        'newTestCategory1',
        'تصنيف تست جديد ١',
        'newTestProduct3',
        'تست جديد ٣ بضاعة',
        1234,
        '1 year',
        '250 (g)',
        '250 (g)',
        12,
        123,
        1234,
      ],
    ],
  },
};
