import Search from '@mui/icons-material/Search';
import { TablePagination, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import * as React from 'react';
import Menu from './Menu';
import TableRowsLoader from './TableRowsLoader';

const Table = (props) => {
  const { columns, data, count, query, setQuery, isLoading } = props;

  const searchOnChange = React.useCallback(
    (e) => {
      if (isLoading) {
        return;
      }
      setQuery({ ...query, name: e.target.value });
    },
    [setQuery, query, isLoading],
  );

  const [page, setPage] = React.useState(
    query.skip === 0 ? 0 : query.skip / 10,
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(query.limit);

  const onPageChange = React.useCallback(
    (event, newPage) => {
      setPage(newPage);
      setQuery({ ...query, skip: newPage * 10 });
    },
    [setPage, page, setQuery, query],
  );
  const onRowsPerPageChange = React.useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
      setQuery({ ...query, limit: parseInt(event.target.value), skip: 0 });
    },
    [setPage, setQuery, query],
  );

  return (
    <TableContainer sx={{ background: '#212b36' }} component={Paper}>
      <TextField
        sx={{
          padding: 2,
          caretColor: 'white',

          ' & > div': { border: '1px solid #929eaa' },
          '& input': { color: 'white', '&::placeholder': { color: '#fff' } },
        }}
        InputProps={{
          startAdornment: (
            <Search
              sx={{
                color: '#fff',

                marginRight: 2,
              }}
            />
          ),
        }}
        placeholder="Search"
        onChange={searchOnChange}
      />
      <MuiTable
        sx={{
          minWidth: 650,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ background: '#2e3944', border: 'none' }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                sx={{ color: '#8793a0' }}
                align={column.align}
                key={column.name}
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRowsLoader rowsNum={rowsPerPage} />
          ) : (
            data.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {Object.values(row.row).map((value, index) => (
                  <TableCell sx={{ color: '#fff' }} key={value + index + index}>
                    {value}
                  </TableCell>
                ))}
                <TableCell align="right">
                  <Menu id={row.id} options={row.actions} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </MuiTable>
      <TablePagination
        sx={{ color: 'white', '& svg': { color: 'white' } }}
        component="div"
        count={count}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  count: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

Table.defaultProps = {
  data: [],
  count: 0,
  query: { limit: 10, skip: 0 },
  setQuery: () => null,
};

export default Table;
