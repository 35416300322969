import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FamilyCard from '../components/Card/FamilyCard';
import LoadingIndicator from '../components/LoadingIndicator';
import useGlobalFilters from '../context/GlobalFilters/useGlobalFilters';
import { urls } from '../routes/routes';
import useFamilies from './Families/useFamilies';

const flexColumnClass = {
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
};

const FamilyOptions = () => {
  const { raw: families, isLoading } = useFamilies();
  const { setFilters, setSelectedFamily } = useGlobalFilters();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && families.count === 1) {
      setFilters({ family: families.data[0].id });
      setSelectedFamily({ selectedFamily: families.data[0] });
      navigate(urls.catalog.main);
    }
  }, [families, setFilters, navigate, isLoading]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: "url('/family_options_background.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: 'auto',
          ...flexColumnClass,
          gap: 3,
        }}
      >
        <Box
          sx={(theme) => ({
            height: 140,
            [theme.breakpoints.down('md')]: { height: 0 },
          })}
        ></Box>
        <Box sx={{ height: 'auto', ...flexColumnClass, gap: 1 }}>
          <img
            src={'/SinokrotHoldingLogo.png'}
            alt={'logo'}
            width={100}
            height={100}
            loading="eager"
          />
          <Box sx={{ height: 'auto', ...flexColumnClass }}>
            <Typography
              textTransform={'full-width'}
              fontSize={32}
              fontWeight={600}
            >
              SINOKROT HOLDING
            </Typography>
            <Typography
              textTransform={'full-width'}
              fontSize={32}
              fontWeight={600}
            >
              سنقرط القابضة
            </Typography>
          </Box>
          <Typography
            fontSize={32}
            sx={(theme) => ({
              color: '#167536',
              [theme.breakpoints.down('md')]: {
                color: 'black',
              },
            })}
            fontWeight={600}
          >
            اختار الشركة لمعرفة المزيد
          </Typography>
        </Box>

        {families.data.length > 0
          ? families.data.map((family) => (
              <FamilyCard
                family={family}
                setFilters={setFilters}
                setSelectedFamily={setSelectedFamily}
                key={family.id}
                id={family.id}
                name={family.name.en}
                logo={family.logo}
                image={family.image}
                redirect={urls.catalog.main}
              />
            ))
          : null}
      </Box>
    </Box>
  );
};

export default FamilyOptions;
