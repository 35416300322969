import { Box, Typography } from '@mui/material';
import React from 'react';
import CatalogItem from './CatalogItem';
import CatalogResultsLoading from './CatalogResultsLoading';

const CatalogResults = (props) => {
  const { data, isLoading, limit } = props;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap', px: 8 }}>
        <CatalogResultsLoading length={limit} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        flexWrap: 'wrap',
        px: 8,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {data.data.length > 0 ? (
        data.data.map((result) => (
          <CatalogItem
            key={result._id}
            id={result._id}
            image={result.image}
            logo={result.logo}
            name={result.name.ar}
            netWeight={result.netWeight}
            drainedWeight={result.drainedWeight}
            unitsPerCarton={result.unitsPerCarton}
            cartonPerPalate={result.cartonPerPalate}
            barcode={result.barcode}
            number={result.number}
          />
        ))
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            width: '100%',
            padding: 8,
          }}
        >
          <Typography variant="h5">No results were found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CatalogResults;
