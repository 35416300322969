import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ProtectedComponent from './auth/ProtectedRoute/ProtectedRoute';
import AuthProvider from './auth/Provider';
import ErrorPage from './error-page';
import './index.css';
import Catalog from './pages/Catalog';
import CategoryListContainer from './pages/Categories/Container';
import CreateCategoryForm from './pages/Categories/Create';
import FamilyListContainer from './pages/Families/Container';
import CreateFamilyForm from './pages/Families/Create';
import FamilyOptions from './pages/FamilyOptions';
import Login from './pages/Login/Login';
import ProductListContainer from './pages/Products/Container';
import ProductForm from './pages/Products/Create';
import SingleProduct from './pages/Products/SingleProduct';
import UploadCategories from './pages/Upload/UploadCategories';
import UploadForm from './pages/Upload/UploadForm';
import UploadProducts from './pages/Upload/UploadProducts';
import UserListContainer from './pages/Users/Container';
import CreateUserForm from './pages/Users/Create';
import UserRoot from './routes/UserRoot';
import AdminRoot from './routes/root';
import { urls } from './routes/routes';

const router = createBrowserRouter([
  {
    path: '/admin/login',
    element: (
      <Login
        api={'/admin/login'}
        redirect={urls.family.list}
        title={'Sinokrot Dashboard Login'}
        dark={true}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/catalog/login',
    element: (
      <Login
        api={'/user/login'}
        redirect={urls.family.user}
        title={'Sinokrot Catalog Login'}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/catalog',
    element: <UserRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'family',
        element: (
          <ProtectedComponent
            apiUrl={'/user/auth'}
            component={FamilyOptions}
            redirectUrl={urls.login.user.url}
          />
        ),
      },
      {
        path: 'main',
        element: (
          <ProtectedComponent
            apiUrl={'/user/auth'}
            component={Catalog}
            redirectUrl={urls.login.user.url}
          />
        ),
      },
      {
        path: 'main/:id',
        element: (
          <ProtectedComponent
            apiUrl={'/user/auth'}
            component={SingleProduct}
            redirectUrl={urls.login.user.url}
          />
        ),
      },
    ],
  },
  {
    path: '/admin',
    element: (
      <ProtectedComponent
        apiUrl={'/admin/auth'}
        component={AdminRoot}
        redirectUrl={urls.login.admin.url}
      />
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'family/create',
        element: (
          <ProtectedComponent
            apiUrl={'/admin/auth'}
            component={CreateFamilyForm}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'family/edit/:id',
        element: (
          <ProtectedComponent
            apiUrl={'/admin/auth'}
            component={CreateFamilyForm}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'family/list',
        element: (
          <ProtectedComponent
            component={FamilyListContainer}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'category/create',
        element: (
          <ProtectedComponent
            component={CreateCategoryForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'category/edit/:id',
        element: (
          <ProtectedComponent
            component={CreateCategoryForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'category/list',
        element: (
          <ProtectedComponent
            component={CategoryListContainer}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'products/create',
        element: (
          <ProtectedComponent
            component={ProductForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'products/edit/:id',
        element: (
          <ProtectedComponent
            component={ProductForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'products/list',
        element: (
          <ProtectedComponent
            component={ProductListContainer}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'users/create',
        element: (
          <ProtectedComponent
            component={CreateUserForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'users/edit/:id',
        element: (
          <ProtectedComponent
            component={CreateUserForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'users/list',
        element: (
          <ProtectedComponent
            component={UserListContainer}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'upload',
        element: (
          <ProtectedComponent
            component={UploadForm}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'upload/categories',
        element: (
          <ProtectedComponent
            component={UploadCategories}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
      {
        path: 'upload/products',
        element: (
          <ProtectedComponent
            component={UploadProducts}
            apiUrl={'/admin/auth'}
            redirectUrl={urls.login.admin.url}
          />
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/catalog/login" replace />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
);
