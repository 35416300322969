import React, { useCallback, useState } from 'react';
import GlobalFiltersContext from './context';

const Provider = ({ children }) => {
  const [filters, setFilters] = useState();
  const [selectedFamily, setSelectedFamily] = useState();

  const handleSelectedFamily = useCallback(
    (selectedFamily) => setSelectedFamily(selectedFamily),
    [setSelectedFamily],
  );
  const handleSetFilters = useCallback(
    (newFilters) => setFilters(newFilters),
    [setFilters],
  );

  return (
    <GlobalFiltersContext.Provider
      value={{
        filters,
        setFilters: handleSetFilters,
        selectedFamily,
        setSelectedFamily: handleSelectedFamily,
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};

export default Provider;
