import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { urls } from '../../routes/routes';
import { getProducts } from '../Products/api';
import { createCategory, deleteCategory, getCategories } from './api';
import { formatCategoriesData } from './utils';

const useCategories = (filters) => {
  const [query, setQuery] = useState({ ...filters, limit: 10, skip: 0 });
  const [modalProps, setModalProps] = useState({ open: false });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: categories, isLoading } = useQuery(
    ['categories', query],
    () => getCategories(query),
    {},
  );

  const {
    mutateAsync: create,
    isError: createError,
    isLoading: createLoading,
  } = useMutation((category) => createCategory(category));

  const {
    mutateAsync: remove,
    isError: deleteError,
    isLoading: deleteLoading,
  } = useMutation((id) => {
    deleteCategory(id);
    queryClient.invalidateQueries(['categories', query]);
  });

  const handleCloseDelete = useCallback(() => {
    setModalProps({
      open: false,
      id: null,
      content: null,
      title: null,
      action: () => null,
    });
  }, []);

  const handleOpenDelete = useCallback(
    async (id) => {
      const products = await getProducts({ limit: 10, skip: 0, category: id });

      setModalProps({
        open: true,
        id,
        content: `Upon deleting this category you will delete ${products.data.count} linked products`,
        title: `Delete`,
        action: async () => {
          await remove(id);
          refetch(query);
          handleCloseDelete();
        },
        actionText: 'Delete Category',
        handleClose: handleCloseDelete,
        actionLoading: deleteLoading,
      });
    },
    [setModalProps, remove, query],
  );

  const refetch = useCallback(
    (newQuery) => setQuery({ ...query, ...newQuery }),
    [setQuery],
  );

  const actions = useMemo(
    () => [
      {
        id: nanoid(),
        name: 'Edit',
        onClick: (id) => navigate(`${urls.category.edit}/${id}`),
      },
      {
        id: nanoid(),
        name: 'Delete',
        onClick: async (id) => await handleOpenDelete(id),
      },
    ],
    [handleOpenDelete],
  );

  if (isLoading) {
    return {
      data: [],
      isLoading,
    };
  }

  const data = formatCategoriesData(categories.data, actions);

  return {
    data,
    isLoading,
    count: categories.data.count,
    refetch,
    query,
    create,
    createError,
    modalProps,
  };
};

export default useCategories;
