import { instance } from '../../apiInstance';

export const upload = async (url, data) => {
  const formData = new FormData();

  // Assuming data.file is a valid File object
  formData.append('file', data.file);

  console.log('Data:', data);
  console.log('FormData:', formData);

  try {
    const uploadedFile = await instance.post(url, formData);
    return uploadedFile.data;
  } catch (error) {
    console.error('Upload Error:', error);
    // Handle the error as needed
    throw error;
  }
};
