import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoadingIndicator from '../../components/LoadingIndicator';
import RHFForm from '../../components/RHFForm';
import RHFTextField from '../../components/RHFTextField';
import { RHFUpload } from '../../components/RHFUpload';
import { urls } from '../../routes/routes';
import { upload } from '../Upload/api';
import { editFamily, getFamilyById } from './api';
import useFamilies from './useFamilies';
import { formatFamilyDefault } from './utils';

const CreateForm = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();
  const { create, createError } = useFamilies();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let logo = data.logo;
      let image = data.image;
      let cover = null;
      if (typeof data.logo !== 'string') {
        const logoFile = await upload(urls.upload.api.image, {
          file: data.logo,
        });
        logo = logoFile.public_id + '.' + logoFile.format;
      }
      if (typeof data.image !== 'string') {
        const imageFile = await upload(urls.upload.api.image, {
          file: data.image,
        });
        image = imageFile.public_id + '.' + imageFile.format;
      }

      if (data.cover.name && typeof data.cover !== 'string') {
        const coverFile = await upload(urls.upload.api.image, {
          file: data.cover,
        });
        cover = coverFile.public_id + '.' + coverFile.format;
      }

      if (!isEmpty(defaultValues) || id) {
        await editFamily(id, { ...data, logo, image, cover });
      } else {
        await create({ ...data, logo, image, cover });
      }
      navigate(urls.family.list);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchFamilyById = async () => {
    setIsLoading(true);
    try {
      const family = await getFamilyById(id);
      setDefaultValues(formatFamilyDefault(family.data));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchFamilyById(id);
    } else {
      setDefaultValues(null);
      setIsLoading(false);
    }
  }, [location.pathname]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <Box>
      <Header title="Create Family" />
      <main>
        <Box sx={{ maxWidth: 500 }}>
          <RHFForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            submit={defaultValues ? 'Edit' : 'Create'}
          >
            {' '}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'nameAr'}
                    label={'Name Ar'}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'nameEn'}
                    label={'Name En'}
                  />
                </Box>
              </Box>
              <Box>
                <RHFUpload label={'Logo'} accept={'image/*'} name={'logo'} />
              </Box>
              <Box>
                <RHFUpload label="Image" accept={'image/*'} name={'image'} />
              </Box>
              <Box>
                <RHFUpload
                  label="Cover Photo"
                  accept={'image/*'}
                  name={'cover'}
                />
              </Box>
            </Box>
          </RHFForm>
        </Box>
      </main>
    </Box>
  );
};

export default CreateForm;
