export const formatProductsData = (data, actions) => {
  return data.data.map((row, index) => {
    return {
      id: row.id,
      row: {
        id: index + 1,
        nameAr: row.name.ar,
        nameEn: row.name.en,
      },
      actions,
    };
  });
};

export const formatProductDefault = (data) => {
  return {
    nameEn: data.data.name.en,
    nameAr: data.data.name.ar,
    subCategory: data.data.subCategory,
    category: data.data.category,
    family: data.data.family,
    logo: data.data.logo,
    image: data.data.image,
    barcode: data.data.barcode,
    number: data.data.number,
    netWeight: data.data.netWeight,
    drainedWeight: data.data.drainedWeight,
    unitsPerCarton: data.data.unitsPerCarton,
    cartonPerPalate: data.data.cartonPerPalate,
  };
};
