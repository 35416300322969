import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../auth/context';

export default function Header(props) {
  const { title, redirect = '/admin/login' } = props;
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutFn = () => {
    logout();
    navigate(redirect);
  };

  return (
    <div style={{ width: '100%' }}>
      <AppBar
        position="static"
        sx={{ background: '#161c24', borderBottom: '1px solid #20262f' }}
      >
        <Toolbar
          sx={{ height: 80, display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <Typography
              fontSize={'1.5rem'}
              fontWeight={500}
              fontFamily={'sans-serif: Georgia'}
            >
              {title}
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ fontSize: '1rem' }}
              color="inherit"
              disableTouchRipple
              disableRipple
              onClick={(e) => logoutFn()}
            >
              <span>Logout</span>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
