import { instance } from '../../apiInstance';
import { urls } from '../../routes/routes';

export const getProducts = (query) =>
  instance.request({
    url: '/product',
    method: 'GET',
    params: query,
  });
export const createProduct = (data) =>
  instance.request({ url: '/product', method: 'POST', data });
export const editProduct = async (id, data) =>
  await instance.put(`${urls.product.api.base}/${id}`, data);
export const deleteProduct = async (id) =>
  await instance.delete(`${urls.product.api.base}/${id}`);

export const getProductById = async (id) =>
  instance.get(`${urls.product.api.base}/${id}`);
