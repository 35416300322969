export const formatFamiliesData = (data, actions) => {
  return data.data.map((row, index) => {
    return {
      id: row.id,
      row: {
        id: index + 1,
        nameAr: row.name.ar,
        nameEn: row.name.en,
      },
      actions,
    };
  });
};

export const formatFamilyDefault = (data) => {
  return {
    nameEn: data.data.name.en,
    nameAr: data.data.name.ar,
    logo: data.data.logo,
    image: data.data.image,
    cover: data.data.cover,
  };
};
