import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { FaFile } from 'react-icons/fa';

const FileUpload = ({ name, accept, label }) => {
  const { register, setValue, getValues } = useFormContext();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Assuming you want to handle only the first file
      const file = acceptedFiles[0];

      // Update form value and display file details
      setValue(name, file);

      // Handle additional actions, if needed
      console.log(file);
    },
    [setValue],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
  });

  return (
    <>
      {label ? <Typography color="white">{label}</Typography> : null}
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #d9d9d9',
          borderRadius: '4px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: isDragActive ? '#2a353e' : '#161c24',
          color: '#ffffff', // Text color
        }}
      >
        <input {...getInputProps()} {...register(name)} />

        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag and drop a file here, or click to select a file</p>
        )}

        {getValues(name) && (
          <div>
            <FaFile style={{ marginRight: '5px' }} />
            {typeof getValues(name) === 'string'
              ? getValues(name)
              : getValues(name).name}
          </div>
        )}
      </div>
    </>
  );
};

export default FileUpload;
