import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Typography } from '@mui/material';
import React from 'react';

const CatalogFilter = ({ name, id, onClick, filters }) => {
  return (
    <Box
      component={'div'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '&:hover': { cursor: 'pointer' },
      }}
      onClick={(e) => {
        if (filters.category !== id) {
          onClick(id);
        } else {
          onClick(null);
        }
      }}
    >
      <Typography sx={{ color: '#167536' }} fontSize={'1.3rem'}>
        {name}
      </Typography>
      {filters.category === id ? (
        <ClearIcon sx={{ color: '#167536' }} />
      ) : (
        <FilterListIcon sx={{ color: '#167536' }} />
      )}
    </Box>
  );
};

export default CatalogFilter;
