import { QueryClient, QueryClientProvider } from 'react-query';
import MUIWrapper from '../MUIWrapper';
import Sidebar from '../components/Sidebar/Sidebar';

const queryClient = new QueryClient();

export default function AdminRoot() {
  return (
    <MUIWrapper>
      <QueryClientProvider client={queryClient}>
        <Sidebar />
      </QueryClientProvider>
    </MUIWrapper>
  );
}
