export const formatUsersData = (data, actions) => {
  return data.data.map((row, index) => {
    return {
      id: row.id,
      row: {
        id: index + 1,
        firstName: row.firstName,
        lastName: row.lastName,
        username: row.username,
      },
      actions,
    };
  });
};

export const formatUserDefault = (data) => {
  return {
    firstName: data.data.firstName,
    lastName: data.data.lastName,
    username: data.data.username,
  };
};
