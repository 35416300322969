import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';
import MUIWrapper from '../MUIWrapper';
import GlobalFiltersProvider from '../context/GlobalFilters/Provider';

const queryClient = new QueryClient();

export default function UserRoot() {
  return (
    <MUIWrapper>
      <QueryClientProvider client={queryClient}>
        <GlobalFiltersProvider>
          <Outlet />
        </GlobalFiltersProvider>
      </QueryClientProvider>
    </MUIWrapper>
  );
}
