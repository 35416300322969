import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import LoadingIndicator from './LoadingIndicator';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 210,
  borderRadius: 4,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DeleteModal = ({
  title,
  content,
  actionText,
  action,
  actionLoading,
  open,
  handleClose,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {content}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 16,
            }}
          >
            <Button
              variant="contained"
              endIcon={actionLoading ? <LoadingIndicator /> : null}
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              endIcon={actionLoading ? <LoadingIndicator /> : null}
              color="error"
              onClick={action}
            >
              {actionText}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
