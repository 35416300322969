import React from 'react';

const Count = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_147_29)" filter="url(#filter0_d_147_29)">
        <path
          d="M27.8947 51.4174C42.0933 51.4174 53.6034 39.9072 53.6034 25.7087C53.6034 11.5102 42.0933 0 27.8947 0C13.6962 0 2.18604 11.5102 2.18604 25.7087C2.18604 39.9072 13.6962 51.4174 27.8947 51.4174Z"
          fill="white"
        />
        <g clip-path="url(#clip1_147_29)">
          <path
            d="M46.3758 15.907H43.0611C42.6964 15.907 42.3981 16.2101 42.3981 16.5806C42.3981 16.9511 42.6964 17.2543 43.0611 17.2543H45.1659L42.3815 21.6751H29.6863L32.4706 17.2543H39.7464C40.111 17.2459 40.401 16.9427 40.401 16.5722C40.3927 16.2101 40.1027 15.9154 39.7464 15.907H32.106C31.8823 15.907 31.6668 16.0249 31.5508 16.2185L28.4764 21.1025L25.402 16.2185C25.2777 16.0249 25.0706 15.907 24.8468 15.907H10.5771C10.2042 15.9154 9.91412 16.2185 9.91412 16.5975C9.91412 16.7238 9.94727 16.8417 10.0136 16.9427L13.5437 22.5508V25.3381C13.5437 25.7086 13.842 26.0117 14.2067 26.0117C14.5713 26.0117 14.8696 25.7086 14.8696 25.3381V23.0308H27.8135V38.6932L14.8696 36.5038V28.7063C14.8613 28.3358 14.563 28.0411 14.1984 28.0411C13.842 28.0495 13.552 28.3442 13.5437 28.7063V37.068C13.5437 37.3964 13.7757 37.6743 14.0989 37.7332L28.3687 40.1499C28.4433 40.1583 28.5178 40.1583 28.5841 40.1499L36.1251 38.87C37.8984 41.0678 40.9314 41.7246 43.434 40.4531C43.7571 40.2846 43.8897 39.8805 43.724 39.5436C43.5583 39.2152 43.1605 39.0805 42.8373 39.2489C42.191 39.5857 41.4783 39.7542 40.7491 39.7542C35.893 39.7373 34.3434 33.0598 38.6608 30.8283C40.9479 29.6578 43.7406 30.5925 44.8924 32.9166C45.5554 34.2555 45.5554 35.8301 44.8924 37.169C44.7267 37.4974 44.851 37.91 45.1825 38.0784C45.5056 38.2469 45.9117 38.1206 46.0774 37.7837C47.5442 34.7776 46.3509 31.1314 43.4008 29.6242V22.5508L46.931 16.9427C47.1216 16.6227 47.0304 16.2101 46.7155 16.008C46.6161 15.9407 46.4918 15.907 46.3758 15.907ZM24.4739 17.2543L27.2583 21.6751H14.5713L11.7869 17.2543H24.4739ZM40.7491 28.9842C36.5145 28.841 33.4236 33.826 35.3627 37.6322L29.1393 38.6932V23.0308H42.0832V29.1442C41.644 29.0347 41.1965 28.9842 40.7491 28.9842Z"
            fill="#167536"
          />
          <path
            d="M40.7491 37.8174C41.1137 37.8174 41.412 37.5142 41.412 37.1437V32.9503C41.412 32.5798 41.1054 32.2766 40.7408 32.2766C40.6414 32.2766 40.5419 32.3019 40.4591 32.344L39.2823 32.9418C38.4039 33.506 39.2409 34.6596 40.0944 34.0365V37.1353C40.0862 37.5142 40.3845 37.8174 40.7491 37.8174Z"
            fill="#167536"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_147_29"
          x="0.186035"
          y="0"
          width="55.4174"
          height="55.4174"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_147_29"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_147_29"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_147_29">
          <rect
            width="51.4174"
            height="51.4174"
            fill="white"
            transform="translate(2.18604)"
          />
        </clipPath>
        <clipPath id="clip1_147_29">
          <rect
            width="37.1163"
            height="25.186"
            fill="white"
            transform="translate(9.91412 15.907)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Count;
