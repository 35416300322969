import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../auth/context';

export default function CatalogHeader(props) {
  const { redirect = '/admin/login', selectedFamily, familiesLength } = props;
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();

  const logoutFn = () => {
    logout();
    navigate(redirect);
  };

  return (
    <Box
      component={'div'}
      sx={(theme) => ({
        background: 'none',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          height: '120px',
          paddingLeft: '150px',
          paddingRight: '80px',
          paddingTop: '30px',
          justifyContent: 'space-between',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          height: '100%',
          minHeight: '250px',
          alignItems: 'center',
          paddingTop: '30px',
          justifyContent: 'space-around',
        },
      })}
    >
      <Button
        sx={{
          height: '3em',
          fontSize: '1rem',
          fontWeight: 600,
        }}
        disabled={familiesLength === 1}
        color="success"
        variant="contained"
        disableTouchRipple
        disableRipple
        onClick={(e) => navigate('/catalog/family')}
      >
        الشركات
      </Button>
      <Box component="div">
        <img
          src={process.env.REACT_APP_CLOUDINARY_URL + selectedFamily.logo}
          alt="logo"
          width="300px"
          height="90px"
        />
      </Box>
    </Box>
  );
}
