import { Box, Button } from '@mui/material';
import React from 'react';
import Header from '../../components/Header/Header';
import { urls } from '../../routes/routes';
import { upload } from './api';

import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import RHFForm from '../../components/RHFForm';
import { RHFUpload } from '../../components/RHFUpload';
import { CSV_DATA } from './constants';

const UploadForm = () => {
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const file = data.file;

      await upload(urls.upload.api.all, { file });
      navigate(urls.family.list);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Header title={'Upload Data'} />
      <main>
        <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
          <Button variant="contained" color="success">
            <CSVLink
              style={{ textDecoration: 'none', color: 'white' }}
              filename={'Example_Upload_All.csv'}
              data={CSV_DATA.all.data}
            >
              Download example CSV
            </CSVLink>
          </Button>
          <Button variant="contained" color="success">
            <CSVLink
              style={{ textDecoration: 'none', color: 'white' }}
              filename={'Example_Upload_All_Headers.csv'}
              data={CSV_DATA.all.headers}
            >
              Download CSV headers
            </CSVLink>
          </Button>
        </div>

        <RHFForm submit="Upload" onSubmit={onSubmit}>
          <RHFUpload label="CSV" name={'file'} accept="text/csv" />
        </RHFForm>
      </main>
    </Box>
  );
};

export default UploadForm;
