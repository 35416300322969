export const adminBase = '/admin';
const catalogBase = '/catalog';
const userBase = '/user';

export const urls = {
  catalog: {
    main: `${catalogBase}/main`,
    api: {
      search: `/search`,
    },
  },
  login: {
    admin: {
      url: `${adminBase}/login`,
      api: `${adminBase}/login`,
    },
    user: {
      url: `${catalogBase}/login`,
      api: `${userBase}/login`,
    },
  },
  family: {
    base: `${adminBase}/family`,
    list: `${adminBase}/family/list`,
    create: `${adminBase}/family/create`,
    edit: `${adminBase}/family/edit`,
    user: `${catalogBase}/family`,
    api: {
      base: '/family',
    },
  },
  category: {
    base: `${adminBase}/category`,
    list: `${adminBase}/category/list`,
    create: `${adminBase}/category/create`,
    edit: `${adminBase}/category/edit`,
    api: {
      base: '/category',
    },
  },
  product: {
    base: `${adminBase}/products`,
    list: `${adminBase}/products/list`,
    create: `${adminBase}/products/create`,
    edit: `${adminBase}/products/edit`,
    api: {
      base: '/product',
    },
  },
  upload: {
    base: `${adminBase}/upload`,
    categories: `${adminBase}/upload/categories`,
    products: `${adminBase}/upload/products`,
    api: {
      all: '/upload/all',
      image: '/upload/image',
      categories: '/upload/categories',
      products: '/upload/products',
    },
  },
  user: {
    base: `${adminBase}/users`,
    list: `${adminBase}/users/list`,
    create: `${adminBase}/users/create`,
    edit: `${adminBase}/users/edit`,
    api: {
      base: '/user',
    },
  },
};
