import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoadingIndicator from '../../components/LoadingIndicator';
import RHFForm from '../../components/RHFForm';
import RHFTextField from '../../components/RHFTextField';
import { urls } from '../../routes/routes';
import { editUser, getUserById } from './api';
import useUsers from './useUsers';
import { formatUserDefault } from './utils';

const CreateForm = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();
  const { create, createError } = useUsers();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (!isEmpty(defaultValues) || id) {
        await editUser(id, { ...data });
      } else {
        await create({ ...data });
      }
      navigate(urls.user.list);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserById = async () => {
    setIsLoading(true);
    try {
      const user = await getUserById(id);
      setDefaultValues(formatUserDefault(user.data));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserById(id);
    } else {
      setDefaultValues(null);
      setIsLoading(false);
    }
  }, [location.pathname]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <Box>
      <Header title="Create User" />
      <main>
        <Box sx={{ maxWidth: 500 }}>
          <RHFForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            submit={defaultValues ? 'Edit' : 'Create'}
          >
            {' '}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'firstName'}
                    label={'First Name'}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'lastName'}
                    label={'Last Name'}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <RHFTextField
                  errorText={'This field is required'}
                  name={'username'}
                  label={'Username'}
                />
              </Box>
              {defaultValues ? null : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'password'}
                    label={'Password'}
                  />
                </Box>
              )}
            </Box>
          </RHFForm>
        </Box>
      </main>
    </Box>
  );
};

export default CreateForm;
