import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, TextField, debounce } from '@mui/material';
import React, { useCallback } from 'react';

const Search = (props) => {
  const { handleSearch } = props;

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 400), [
    handleSearch,
  ]);

  return (
    <Box
      sx={(theme) => ({
        alignSelf: 'flex-end',
        [theme.breakpoints.up('md')]: {
          // mt: 16,
          pr: 10,
          // pt: 10,
        },
        [theme.breakpoints.down('md')]: {
          // mt: 6,
          pr: 5,
          pb: 2,
          // pt: 10,
        },
      })}
    >
      <TextField
        sx={{
          borderRadius: 2,
          background: 'white',
          width: '20rem',
        }}
        onChange={debouncedHandleSearch}
        InputProps={{
          startAdornment: (
            <Button
              variant="contained"
              color="success"
              endIcon={<SearchIcon />}
              sx={{ fontWeight: 600, marginRight: 2 }}
            >
              <span>بحث</span>
            </Button>
          ),
        }}
      />
    </Box>
  );
};

export default Search;
