import { instance } from '../../apiInstance';
import { urls } from '../../routes/routes';

export const getFamilies = async (query) =>
  instance.request({
    url: '/family',
    method: 'GET',
    params: query,
  });
export const createFamily = (data) =>
  instance.request({ url: '/family', method: 'POST', data });
export const editFamily = async (id, data) =>
  await instance.put(`${urls.family.api.base}/${id}`, data);
export const deleteFamily = async (id) =>
  await instance.delete(`${urls.family.api.base}/${id}`);

export const getFamilyById = async (id) =>
  instance.get(`${urls.family.api.base}/${id}`);
