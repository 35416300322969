import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Link, ListItem } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link as NavLink, useLocation } from 'react-router-dom';

export default function DrawerItem({ list, text, href, icon }) {
  let location = useLocation();

  return (
    <Box sx={{ width: '100%' }}>
      <Accordion
        elevation={0}
        defaultExpanded={location.pathname.includes(href)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ '& svg': { color: '#50575e' } }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {icon}
          <Typography>{text}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {list.map((item, index) => (
            <Link
              key={item.href}
              underline="none"
              component={NavLink}
              to={item.href}
            >
              <ListItem disablePadding>
                <ListItemText
                  sx={{ p: 1 }}
                  primary={
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                    >
                      {item.icon}
                      {item.text}
                    </div>
                  }
                />
              </ListItem>
            </Link>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
