import { Card, CardMedia, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const CatalogItem = (props) => {
  const { image, name, netWeight, unitsPerCarton, barcode, number, id, logo } =
    props;

  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(`${id}`), [id, navigate]);

  return (
    <Card
      onClick={onClick}
      sx={{
        width: 200,
        padding: 2,
        background: 'none',
        boxShadow: 'none !important',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CardMedia
        component="img"
        height="100"
        image={process.env.REACT_APP_CLOUDINARY_URL + logo}
        alt="product image"
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 8,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <Typography variant="body2" fontWeight={600} color="#167536">
          {name}
        </Typography>
        <Typography variant="body2">الباركــــــود: {barcode}</Typography>
        <Typography variant="body2">{netWeight} :الــــــــوزن</Typography>
        <Typography variant="body2">
          الــــــــــعدد: {unitsPerCarton}
        </Typography>
      </div>
    </Card>
  );
};

export default CatalogItem;
