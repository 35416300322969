import { instance } from '../../apiInstance';
import { urls } from '../../routes/routes';

export const getCategories = (query) =>
  instance.request({
    url: '/category',
    method: 'GET',
    params: { ...query, limit: query.limit, skip: query.skip },
  });

export const getUserCategories = (query) =>
  instance.request({
    url: '/category/user',
    method: 'GET',
    params: { ...query, limit: query.limit, skip: query.skip },
  });

export const createCategory = (data) =>
  instance.request({ url: '/category', method: 'POST', data });
export const editCategory = async (id, data) =>
  await instance.put(`${urls.category.api.base}/${id}`, data);
export const deleteCategory = async (id) =>
  await instance.delete(`${urls.category.api.base}/${id}`);

export const getCategoryById = async (id) =>
  instance.get(`${urls.category.api.base}/${id}`);
