import React from 'react';

const Barcode = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_148_50)" filter="url(#filter0_d_148_50)">
        <path
          d="M27.8483 51.743C42.0468 51.743 53.557 40.2328 53.557 26.0343C53.557 11.8357 42.0468 0.325562 27.8483 0.325562C13.6497 0.325562 2.13956 11.8357 2.13956 26.0343C2.13956 40.2328 13.6497 51.743 27.8483 51.743Z"
          fill="white"
        />
        <g clip-path="url(#clip1_148_50)">
          <path
            d="M11.6186 16.2325H13.49V38.7674H11.6186V16.2325ZM14.5296 38.7674H15.7772V16.2325H14.5296V38.7674ZM16.4011 38.7674H17.4407V16.2325H16.4011V38.7674ZM18.8963 38.7674H21.1835V16.2325H18.8963V38.7674ZM23.0549 38.7674H24.0946V16.2325H23.0549V38.7674ZM25.966 38.7674H30.7485V16.2325H25.966V38.7674ZM38.2342 38.7674H39.8976V16.2325H38.2342V38.7674ZM41.1452 16.2325V38.7674H43.2246V16.2325H41.1452ZM32.6199 38.7674H33.6596V16.2325H32.6199V38.7674ZM35.1151 38.7674H36.1548V16.2325H35.1151V38.7674Z"
            fill="#167536"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_148_50"
          x="0.139557"
          y="0.325562"
          width="55.4174"
          height="55.4174"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_148_50"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_148_50"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_148_50">
          <rect
            width="51.4174"
            height="51.4174"
            fill="white"
            transform="translate(2.13956 0.325562)"
          />
        </clipPath>
        <clipPath id="clip1_148_50">
          <rect
            width="31.814"
            height="22.5349"
            fill="white"
            transform="translate(11.6186 16.2325)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Barcode;
