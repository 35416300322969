import { Box, Paper } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoadingIndicator from '../../components/LoadingIndicator';
import RHFForm from '../../components/RHFForm';
import RHFSelect from '../../components/RHFSelect';
import RHFTextField from '../../components/RHFTextField';
import { RHFUpload } from '../../components/RHFUpload';
import { urls } from '../../routes/routes';
import useCategories from '../Categories/useCategories';
import useFamilies from '../Families/useFamilies';
import { upload } from '../Upload/api';
import { editProduct, getProductById } from './api';
import useProducts from './useProducts';
import { formatProductDefault } from './utils';

const CreateForm = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { create, createError } = useProducts();

  const {
    data: families,
    refetch: fetchFamilies,
    isLoading: isLoadingFamilies,
  } = useFamilies();
  const {
    data: categories,
    refetch: fetchCategories,
    isLoading: isLoadingCategories,
  } = useCategories();

  const getFamilies = useCallback(
    (query) => fetchFamilies(query),
    [fetchFamilies],
  );
  const getCategories = useCallback(
    (query) => fetchCategories(query),
    [fetchCategories],
  );

  const familyOptions = useMemo(() => {
    if (families && families.length > 0) {
      return families.map((family) => ({
        label: family.row.nameEn,
        value: family.id,
      }));
    }
    return [];
  }, [families]);

  const categoryOptions = useMemo(() => {
    if (categories && categories.length > 0) {
      return categories.map((category) => ({
        label: category.row.nameEn,
        value: category.id,
      }));
    }
    return [];
  }, [categories]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let logo = data.logo;
      let image = data.image;
      if (typeof data.logo !== 'string') {
        const logoFile = await upload(urls.upload.api.image, {
          file: data.logo,
        });
        logo = logoFile.public_id + '.' + logoFile.format;
      }
      if (typeof data.logo !== 'string') {
        const imageFile = await upload(urls.upload.api.image, {
          file: data.image,
        });

        image = imageFile.public_id + '.' + imageFile.format;
      }

      if (isNaN(data.barcode) || isNaN(data.number)) {
        //throw error
        return;
      }
      if (defaultValues || id) {
        await editProduct(id, {
          ...data,
          barcode: +data.barcode,
          number: +data.number,
          unitsPerCarton: +data.unitsPerCarton,
          cartonPerPalate: +data.cartonPerPalate,
          logo,
          image,
        });
      } else {
        await create({
          ...data,
          barcode: +data.barcode,
          number: +data.number,
          unitsPerCarton: +data.unitsPerCarton,
          cartonPerPalate: +data.cartonPerPalate,
          logo,
          image,
        });
      }
      navigate(urls.product.list);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductById = async () => {
    setIsLoading(true);
    try {
      const product = await getProductById(id);
      setDefaultValues(formatProductDefault(product.data));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProductById(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <Paper>
      <Header title="Create Product" />
      <main>
        <Box sx={{ maxWidth: 500 }}>
          <RHFForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            submit={defaultValues ? 'Edit' : 'Create'}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'nameAr'}
                    label={'Name Ar'}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'nameEn'}
                    label={'Name En'}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <RHFTextField
                      required={false}
                      name={'barcode'}
                      label={'Barcode'}
                      type={'number'}
                    />
                  </Box>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <RHFTextField
                      required={false}
                      name={'number'}
                      label={'Code'}
                      type={'number'}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <RHFTextField
                      required={false}
                      name={'netWeight'}
                      label={'Net Weight with unit'}
                    />
                  </Box>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <RHFTextField
                      required={false}
                      name={'drainedWeight'}
                      label={'Drained Weight with unit'}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <RHFTextField
                      required={false}
                      name={'unitsPerCarton'}
                      label={'Units Per Carton'}
                      type={'number'}
                    />
                  </Box>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    <RHFTextField
                      required={false}
                      name={'cartonPerPalate'}
                      label={'Carton Per Palate'}
                      type={'number'}
                    />
                  </Box>
                </Box>
              </Box>
              {!isLoadingFamilies ? (
                <Box sx={{ width: '100%' }}>
                  <RHFSelect
                    onChange={(e) => getCategories({ family: e.target.value })}
                    label="Family"
                    name="family"
                    options={familyOptions}
                    placeholder="Family"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <LoadingIndicator color={'primary'} size={40} />
                </Box>
              )}
              {!isLoadingCategories ? (
                <Box sx={{ width: '100%' }}>
                  <RHFSelect
                    label="Category"
                    name="category"
                    options={categoryOptions}
                    placeholder="Category"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <LoadingIndicator color={'primary'} size={40} />
                </Box>
              )}
              <Box>
                <RHFUpload accept={'image/*'} name={'logo'} label={'Icon'} />
              </Box>
              <Box>
                <RHFUpload accept={'image/*'} name={'image'} label={'image'} />
              </Box>
            </Box>
          </RHFForm>
        </Box>
      </main>
    </Paper>
  );
};

export default CreateForm;
