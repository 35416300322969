import React, { useMemo } from 'react';
import Table from '../../components/Table/Table';

const UserList = (props) => {
  const { data, count, query, setQuery, isLoading } = props;

  const columns = useMemo(
    () => [
      {
        name: 'id',
        align: 'left',
      },
      {
        name: 'First Name',
        align: 'left',
      },
      {
        name: 'Last Name',
        align: 'left',
      },
      {
        name: 'Username',
        align: 'left',
      },
      {
        name: 'Actions',
        align: 'right',
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      data={data}
      count={count}
      setQuery={setQuery}
      query={query}
      isLoading={isLoading}
    />
  );
};

export default UserList;
