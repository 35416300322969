import React from 'react';

const Name = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_147_43)" filter="url(#filter0_d_147_43)">
        <path
          d="M27.825 51.743C42.0235 51.743 53.5337 40.2328 53.5337 26.0343C53.5337 11.8357 42.0235 0.325562 27.825 0.325562C13.6264 0.325562 2.11627 11.8357 2.11627 26.0343C2.11627 40.2328 13.6264 51.743 27.825 51.743Z"
          fill="#FBBC3A"
        />
        <g clip-path="url(#clip1_147_43)">
          <path
            d="M43.0355 17.2689L27.8516 9.67692C27.6347 9.56847 27.4177 9.56847 27.2008 9.67692L12.0169 17.2689C11.7638 17.3773 11.6192 17.6304 11.6192 17.9196V33.1036C11.6192 33.3928 11.7638 33.6097 12.0169 33.7543L27.2008 41.3463C27.3093 41.3824 27.4177 41.4186 27.5262 41.4186C27.6347 41.4186 27.7431 41.3824 27.8516 41.3463L43.0355 33.7543C43.2886 33.6458 43.4332 33.3928 43.4332 33.1036V17.9196C43.4332 17.6304 43.2886 17.3773 43.0355 17.2689ZM27.5262 11.123L41.0833 17.9196L38.1911 19.3657L24.634 12.5691L27.5262 11.123ZM32.4067 22.2579L18.8497 15.4613L23.0072 13.3644L36.5642 20.1611L32.4067 22.2579ZM32.949 23.5955L37.2873 21.4264V26.8131L35.9135 25.837C35.7689 25.7285 35.552 25.6924 35.3712 25.7285C35.1905 25.7647 35.0097 25.8731 34.9012 26.0177L32.949 28.8014V23.5955ZM17.2228 16.2928L30.7799 23.0532L27.5262 24.6801L13.9691 17.9196L17.2228 16.2928ZM13.0653 19.0765L26.8031 25.9454V39.5025L13.0653 32.6336V19.0765ZM28.2492 39.5387V25.9454L31.5029 24.3186V31.1152C31.5029 31.4406 31.7199 31.6936 32.0091 31.8021C32.0814 31.8382 32.1537 31.8382 32.226 31.8382C32.4429 31.8382 32.696 31.7298 32.8044 31.5129L35.6243 27.4277L37.5404 28.8014C37.7573 28.9461 38.0465 28.9822 38.2996 28.8738C38.5526 28.7653 38.6972 28.5122 38.6972 28.223V20.7034L41.9509 19.0765V32.6336L28.2492 39.5387Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_147_43"
          x="0.116272"
          y="0.325562"
          width="55.4174"
          height="55.4174"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_147_43"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_147_43"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_147_43">
          <rect
            width="51.4174"
            height="51.4174"
            fill="white"
            transform="translate(2.11627 0.325562)"
          />
        </clipPath>
        <clipPath id="clip1_147_43">
          <rect
            width="31.814"
            height="31.814"
            fill="white"
            transform="translate(11.6192 9.60461)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Name;
