import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoadingIndicator from '../../components/LoadingIndicator';
import RHFForm from '../../components/RHFForm';
import RHFSelect from '../../components/RHFSelect';
import RHFTextField from '../../components/RHFTextField';
import { RHFUpload } from '../../components/RHFUpload';
import { urls } from '../../routes/routes';
import useFamilies from '../Families/useFamilies';
import { upload } from '../Upload/api';
import { editCategory, getCategoryById } from './api';
import useCategories from './useCategories';
import { formatCategoryDefault } from './utils';

const CreateForm = () => {
  const { id } = useParams();
  const [defaultValues, setDefaultValues] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { create, createError } = useCategories();
  const { data: families, isLoading: isLoadingFamilies } = useFamilies();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const familyOptions = useMemo(() => {
    if (families && families.length > 0) {
      return families.map((family) => ({
        label: family.row.nameEn,
        value: family.id,
      }));
    }
    return [];
  }, [families]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let logo = data.logo;
      let image = data.image;
      if (typeof data.logo !== 'string') {
        const logoFile = await upload(urls.upload.api.image, {
          file: data.logo,
        });
        logo = logoFile.public_id + '.' + logoFile.format;
      }
      if (typeof data.logo !== 'string') {
        const imageFile = await upload(urls.upload.api.image, {
          file: data.image,
        });

        image = imageFile.public_id + '.' + imageFile.format;
      }

      if (defaultValues || id) {
        await editCategory(id, { ...data, logo, image });
      } else {
        await create({ ...data, logo, image });
      }
      navigate('/admin/category/list');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoryById = async () => {
    setIsLoading(true);
    try {
      const subCategory = await getCategoryById(id);
      setDefaultValues(formatCategoryDefault(subCategory.data));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCategoryById(id);
    } else {
      setDefaultValues(null);
      setIsLoading(false);
    }
  }, [id]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <div>
      <Header title="Create Category" />
      <main>
        <Box sx={{ maxWidth: 500 }}>
          <RHFForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            submit={defaultValues ? 'Edit' : 'Create'}
          >
            {' '}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'nameAr'}
                    label={'Name Ar'}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <RHFTextField
                    errorText={'This field is required'}
                    name={'nameEn'}
                    label={'Name En'}
                  />
                </Box>
              </Box>
              {!isLoadingFamilies ? (
                <Box
                  sx={{
                    width: '100%',
                    display: defaultValues ? 'none' : 'initial',
                  }}
                >
                  <RHFSelect
                    label="Family"
                    name="family"
                    options={familyOptions}
                    placeholder="Family"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <LoadingIndicator color={'primary'} size={40} />
                </Box>
              )}
              <Box>
                <RHFUpload accept={'image/*'} name={'logo'} label={'Logo'} />
              </Box>
              <Box>
                <RHFUpload accept={'image/*'} name={'image'} label={'image'} />
              </Box>
            </Box>
          </RHFForm>
        </Box>
      </main>
    </div>
  );
};

export default CreateForm;
