import { instance } from '../../apiInstance';
import { urls } from '../../routes/routes';

export const getUsers = async (query) =>
  instance.request({
    url: '/user',
    method: 'GET',
    params: query,
  });
export const createUser = (data) =>
  instance.request({ url: '/user', method: 'POST', data });
export const editUser = async (id, data) =>
  await instance.put(`${urls.user.api.base}/${id}`, data);
export const deleteUser = async (id) =>
  await instance.delete(`${urls.user.api.base}/${id}`);

export const getUserById = async (id) =>
  instance.get(`${urls.user.api.base}/profile/${id}`);
