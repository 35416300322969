import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import AuthContext from '../context';

const ProtectedComponent = ({ component: Component, redirectUrl, apiUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { checkAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const validateToken = async (token) => {
    setIsLoading(true);
    try {
      await checkAuth(apiUrl, token);
    } catch (e) {
      localStorage.setItem('token', null);
      setIsLoading(false);
      navigate(redirectUrl);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    validateToken(token);
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return <Component />;
};

export default ProtectedComponent;
