import { FormHelperText, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const RHFTextField = ({ name, label, errorText, type, required = true }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field, fieldState: { error } }) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Typography color="white" fontSize={'0.8rem'}>
              {label}
            </Typography>
            <TextField
              {...field}
              error={!!error}
              placeholder={label}
              type={type}
              sx={{
                '& .MuiInputBase-root': {
                  caretColor: 'white',
                  border: '1px solid #929eaa',
                },
                '& input': {
                  color: 'white',
                  '&::placeholder': { color: '#fff' },
                },
                ' p': {
                  padding: 0,
                  margin: 0,
                  textAlign: 'start',
                },
              }}
              helperText={
                !!error && (
                  <FormHelperText error={!!error}>{errorText}</FormHelperText>
                )
              }
            />
          </div>
        )}
      />
    </>
  );
};

RHFTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  type: PropTypes.string,
};
RHFTextField.defaultProps = {
  errorText: 'This field is required',
  type: 'text',
};

export default RHFTextField;
