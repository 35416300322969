import { instance } from '../apiInstance';
import { login, validateToken } from './api';
import AuthContext from './context';

const authProvider = {
  login: async (url, data) => {
    console.log(data);
    const user = await login(url, data);
    instance.defaults.headers.common = {
      Authorization: `Bearer ${user.data.token}`,
    };
    localStorage.setItem('token', user.data.token);
  },
  logout: () => localStorage.setItem('token', null),
  checkAuth: async (url, token) => {
    await validateToken(url, token);
    instance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    localStorage.setItem('token', token);
  },
};

const AuthProvider = ({ children }) => {
  return (
    <AuthContext.Provider value={authProvider}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
