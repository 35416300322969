import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Barcode from '../../Icons/Barcode.jsx';
import Count from '../../Icons/Count.jsx';
import Name from '../../Icons/Name.jsx';
import Weight from '../../Icons/Weight.jsx';
import LoadingIndicator from '../../components/LoadingIndicator';
import useGlobalFilters from '../../context/GlobalFilters/useGlobalFilters.js';
import useSearch from '../../hooks/useSearch.js';
import { urls } from '../../routes/routes.js';
import { getProductById } from './api';

const iconTitle = {
  color: '#FBBC3A',
  fontSize: '20px',
  fontWeight: 'bold',
};
const cost = {
  color: '#FBBC3A',
  fontSize: '48px',
  fontWeight: 'bold',
  alignSelf: 'start',
};
const iconText = {
  color: '#fff',
  fontSize: '16px',
  wordBreak: 'break-word',
  maxWidth: '75px',
};
const description = { color: '#fff', fontSize: '24px' };
const title = {
  color: '#fff',
  fontSize: '50px',
  wordBreak: 'break-word',
  textAlign: 'center',
};

const SingleProduct = () => {
  const { filters, setFilters, selectedFamily, setSelectedFamily } =
    useGlobalFilters();
  const theme = useTheme();
  const { setQuery, query, isLoading: isLoadingSearch } = useSearch(filters);
  const navigate = useNavigate();
  const param = useParams();
  const { data, isLoading } = useQuery(
    [param, 'product'],
    () => getProductById(param.id),
    {},
  );

  const product = useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      name: data.data.data.name.ar,
      description: data.data.data.description?.en ?? '',
      cost: data.data.data.cost ?? '',
      barcode: data.data.data.barcode,
      image: data.data.data.image,
      logo: data.data.data.logo,
      number: data.data.data.number,
      netWeight: data.data.data.netWeight,
      drainedWeight: data.data.data.drainedWeight,
      unitsPerCarton: data.data.data.unitsPerCarton,
      cartonPerPalate: data.data.data.cartonPerPalate,
    };
  }, [data]);

  useEffect(() => {
    if (!filters) {
      const family = localStorage.getItem('family');
      const selectedFamily = localStorage.getItem('selectedFamily');
      if (family) {
        setFilters({ family });
        setSelectedFamily({ selectedFamily: JSON.parse(selectedFamily) });
        setQuery({ ...query, family });
      } else {
        navigate(urls.family.user);
      }
    }
  }, [filters]);

  useEffect(() => {
    document.body.style.backgroundColor = '#167536';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  if (isLoading || isLoadingSearch) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        })}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundImage: "url('/SingleProductBackground.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          backgroundImage: "url('/SingleProductBackgroundMobile.png')",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          height: 'auto',
          padding: 4,
          px: 8,
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('md')]: {
            px: 2,
          },
        })}
      >
        <Button
          disableRipple
          onClick={() => navigate(urls.catalog.main)}
          sx={(theme) => ({
            background: '#fff',
            minWidth: '0.5rem',
            height: '2rem',
            padding: 2,
            paddingRight: 1,
            py: 3,
            '&:hover': { background: '#CDCDCD', cursor: 'pointer' },
          })}
        >
          <ArrowBackIosIcon sx={{ color: '#167536' }} />
        </Button>
        <Box
          component="div"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              width: '50%',
              height: '50%',
              pr: 1,
            },
          })}
        >
          <img
            src={process.env.REACT_APP_CLOUDINARY_URL + selectedFamily.logo}
            alt="logo"
            style={{ width: '100%', maxWidth: '400px', maxHeight: '350px' }}
          />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '50%',
            marginTop: -10,
            gap: 6,
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              margin: 0,
              width: '80%',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'center',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              height: 'auto',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              [theme.breakpoints.down('md')]: {
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center',
              },
            })}
          >
            <Typography variant="h5" sx={title}>
              {product.name}
            </Typography>
            <Typography variant="body1" sx={description}>
              {product.description}
            </Typography>
            <Typography variant="body1" sx={cost}>
              {product.cost}
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              height: 'auto',
              display: 'flex',
              gap: 10,
              flexWrap: 'wrap',

              [theme.breakpoints.down('md')]: {
                justifyContent: 'center',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                minHeight: '142px',
                minWidth: '95px',
              })}
            >
              <Count />
              <Typography sx={iconTitle}>بالكرتونة</Typography>
              <Typography sx={iconText}>{product.unitsPerCarton}</Typography>
            </Box>
            <Box
              sx={(theme) => ({
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                minHeight: '142px',
                minWidth: '95px',
              })}
            >
              <Weight />
              <Typography sx={iconTitle}>الــــــــــوزن</Typography>
              <Typography sx={iconText}>{product.netWeight}</Typography>
            </Box>
            <Box
              sx={(theme) => ({
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                minHeight: '142px',
                minWidth: '95px',
              })}
            >
              <Barcode />
              <Typography sx={iconTitle}>الباركــــــود</Typography>
              <Typography sx={iconText}>{product.barcode}</Typography>
            </Box>
            <Box
              sx={(theme) => ({
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                minHeight: '142px',
                minWidth: '95px',
              })}
            >
              <Name />
              <Typography sx={iconTitle}>الــــــــــعدد</Typography>
              <Typography sx={iconText}>{product.cartonPerPalate}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              order: -1,
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
          })}
        >
          <Box
            component="div"
            sx={(theme) => ({
              width: '70%',
              height: '70%',
              pb: 10,
              [theme.breakpoints.down('md')]: {
                width: '90%',
                height: '90%',
                p: 0,
              },
            })}
          >
            <img
              height="100%"
              width="100%"
              src={process.env.REACT_APP_CLOUDINARY_URL + product.image}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleProduct;
