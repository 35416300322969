import AddBoxIcon from '@mui/icons-material/AddBox';
import CategoryIcon from '@mui/icons-material/Category';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DvrIcon from '@mui/icons-material/Dvr';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InventoryIcon from '@mui/icons-material/Inventory';
import PostAddIcon from '@mui/icons-material/PostAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { urls } from '../../routes/routes';

export const drawerList = [
  {
    text: 'Families',
    href: urls.family.base,
    icon: <GroupIcon sx={{ color: '#637281 !important', marginRight: 2 }} />,
    list: [
      {
        text: 'List',
        href: urls.family.list,
        icon: (
          <Diversity3Icon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
      {
        text: 'Create',
        href: urls.family.create,
        icon: (
          <GroupAddIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
    ],
  },
  {
    text: 'Categories',
    href: urls.category.base,
    icon: <CategoryIcon sx={{ color: '#637281 !important', marginRight: 2 }} />,
    list: [
      {
        text: 'List',
        href: urls.category.list,
        icon: (
          <ViewModuleIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
      {
        text: 'Create',
        href: urls.category.create,
        icon: (
          <PostAddIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
    ],
  },
  {
    text: 'Products',
    href: urls.product.base,
    icon: (
      <InventoryIcon sx={{ color: '#637281 !important', marginRight: 2 }} />
    ),
    list: [
      {
        text: 'List',
        href: urls.product.list,
        icon: (
          <DvrIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
      {
        text: 'Create',
        href: urls.product.create,
        icon: (
          <AddBoxIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
    ],
  },
  {
    text: 'Upload',
    href: urls.upload.base,
    icon: (
      <FileUploadIcon sx={{ color: '#637281 !important', marginRight: 2 }} />
    ),
    list: [
      {
        text: 'Upload All',
        href: urls.upload.base,
        icon: (
          <UploadFileIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
      {
        text: 'Upload Categories',
        href: urls.upload.categories,
        icon: (
          <UploadFileIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
      {
        text: 'Upload Products',
        href: urls.upload.products,
        icon: (
          <UploadFileIcon
            sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
          />
        ),
      },
    ],
  },
  // {
  //   text: 'Users',
  //   href: urls.user.base,
  //   icon: (
  //     <PersonOutlineIcon sx={{ color: '#637281 !important', marginRight: 2 }} />
  //   ),
  //   list: [
  //     {
  //       text: 'List',
  //       href: urls.user.list,
  //       icon: (
  //         <PeopleOutlineIcon
  //           sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
  //         />
  //       ),
  //     },
  //     {
  //       text: 'Create',
  //       href: urls.user.create,
  //       icon: (
  //         <PersonAddAltIcon
  //           sx={{ color: '#637281 !important', marginLeft: 2, marginRight: 1 }}
  //         />
  //       ),
  //     },
  //   ],
  // },
];
