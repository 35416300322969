import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { searchRequest } from './api';

const useSearch = (filters) => {
  const [query, setQuery] = useState({
    ...filters,
    limit: 25,
    skip: 0,
    search: '',
  });

  const { data, isLoading } = useQuery(
    ['search', query],
    () => searchRequest(query),
    { refetchOnWindowFocus: false },
  );

  const handleSetQuery = useCallback(
    (filters) => setQuery(filters),
    [setQuery],
  );

  if (isLoading) {
    return {
      data: [],
      isLoading,
      setQuery: handleSetQuery,
      query,
    };
  }

  return {
    data: data.data,
    isLoading,
    setQuery: handleSetQuery,
    query,
  };
};

export default useSearch;
