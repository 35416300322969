import { Box, Button } from '@mui/material';
import React, { useMemo } from 'react';
import Header from '../../components/Header/Header';
import { urls } from '../../routes/routes';
import { upload } from './api';

import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import RHFForm from '../../components/RHFForm';
import RHFSelect from '../../components/RHFSelect';
import { RHFUpload } from '../../components/RHFUpload';
import useFamilies from '../Families/useFamilies';
import { CSV_DATA } from './constants';

const UploadCategories = () => {
  const { data: families, isLoading: isLoadingFamilies } = useFamilies();
  const navigate = useNavigate();

  const familyOptions = useMemo(() => {
    if (families && families.length > 0) {
      return families.map((family) => ({
        label: family.row.nameEn,
        value: family.id,
      }));
    }
    return [];
  }, [families]);

  const onSubmit = async (data) => {
    try {
      const file = data.file;

      await upload(`${urls.upload.api.categories}/${data.family}`, { file });
      navigate(urls.family.list);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Header title={'Upload Data'} />
      <main>
        <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
          <Button variant="contained" color="success">
            <CSVLink
              style={{ textDecoration: 'none', color: 'white' }}
              filename={'Example_Upload_Categories.csv'}
              data={CSV_DATA.categories.data}
            >
              Download example CSV
            </CSVLink>
          </Button>
          <Button variant="contained" color="success">
            <CSVLink
              style={{ textDecoration: 'none', color: 'white' }}
              filename={'Example_Upload_Categories_Headers.csv'}
              data={CSV_DATA.categories.headers}
            >
              Download CSV headers
            </CSVLink>
          </Button>
        </div>

        <RHFForm submit="Upload" onSubmit={onSubmit}>
          {!isLoadingFamilies ? (
            <Box sx={{ width: '100%' }}>
              <RHFSelect
                label="Family"
                name="family"
                options={familyOptions}
                placeholder="Family"
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <LoadingIndicator color={'primary'} size={40} />
            </Box>
          )}
          <RHFUpload label="CSV" name={'file'} accept="text/csv" />
        </RHFForm>
      </main>
    </Box>
  );
};

export default UploadCategories;
