import { Card, Skeleton } from '@mui/material';
import React from 'react';

const CatalogResultsLoading = ({ length }) => {
  return [...Array(length)].map((item, index) => (
    <Card
      key={index}
      sx={{
        width: 200,
        padding: 2,
        background: 'none',
        boxShadow: 'none !important',
      }}
    >
      <Skeleton variant="rounded" width={210} height={60} />

      <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />
    </Card>
  ));
};

export default CatalogResultsLoading;
