import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../auth/context';
import LoadingIndicator from '../../components/LoadingIndicator';

const Login = (props) => {
  const { api, redirect, title, dark } = props;
  const { login } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await login(api, data);
      navigate(redirect);
    } catch (e) {
      setError('login', { message: 'Incorrect username or password' });
    }
  };

  useEffect(() => {
    if (!dark) {
      onSubmit({ username: 'test', password: 'test' });
    } else {
      setIsLoading(false);
    }
  }, [dark]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: dark ? '#161c24' : '#f2f2f2',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 300,
          minHeight: 500,
          justifyContent: 'space-around',
          alignItems: 'center',
          background: 'white',
          borderRadius: 3,
          padding: 8,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            width: 350,
          }}
        >
          <Typography textAlign={'center'} fontSize={28} fontWeight={700}>
            {title}
          </Typography>
          <img
            src={dark ? '/SinokrotHoldingLogo.png' : '/SinokrotLogo.png'}
            alt={'logo'}
            width={100}
            height={100}
            loading="eager"
          />
        </Box>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            width: 350,
          }}
          onSubmit={handleSubmit(onSubmit)}
          onChange={() => clearErrors()}
        >
          <div style={{ width: '100%' }}>
            <TextField
              sx={{ width: '100%' }}
              label={'Username'}
              variant="standard"
              {...register('username', { required: true })}
            />
          </div>
          <div style={{ width: '100%' }}>
            <TextField
              sx={{ width: '100%' }}
              label="Password"
              variant="standard"
              type={'password'}
              {...register('password', { required: true })}
            />
          </div>
          {errors.login ? (
            <Typography color="error">{errors.login.message}</Typography>
          ) : null}
          <Button
            sx={{
              alignSelf: 'center',
              marginTop: 5,
              height: 50,
              borderRadius: 12,
              width: '100%',
            }}
            variant="contained"
            type="submit"
          >
            Login
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
