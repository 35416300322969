import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import DrawerItem from './DrawerItem';
import { drawerList } from './constants';

const classes = {
  drawer: {
    background: '#161c24',
    borderRight: '2px solid #20262f',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    ' & > div > div > div': { borderRight: '4px solid #20262f' },
    '& div': {
      background: '#161c24',
      color: '#929eaa',
    },
    '& svg': { color: '#929eaa' },
    '& .MuiPaper-root': { boxShadow: '0px 0px 0px 0px white !important' },
  },
  listHeader: {
    fontSize: '1.5 rem',
    fontWeight: 600,
    color: '#637281',
    fontFamily: 'Serif: Georgia',
  },
};

const drawerWidth = 240;

function Sidebar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <List>
        <ListItem sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <img
            src={'/SinokrotHoldingLogo.png'}
            alt={'Logo'}
            width={50}
            height={50}
            loading="eager"
          />
          <Typography width={'101%'} p={2} pl={4} sx={classes.listHeader}>
            Sinokrot Dashboard
          </Typography>
        </ListItem>
        {drawerList.map((item, index) => (
          <ListItem key={item.text + index} disablePadding>
            <DrawerItem
              list={item.list}
              text={item.text}
              href={item.href}
              icon={item.icon}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          ...classes.drawer,
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box id="detail" sx={{ width: '100%', height: '100%' }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Sidebar;
