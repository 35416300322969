import { useCallback, useContext } from 'react';
import GlobalFiltersContext from './context';

const useGlobalFilters = () => {
  const { filters, setFilters, setSelectedFamily, selectedFamily } =
    useContext(GlobalFiltersContext);

  const handleSetFilters = useCallback(
    (newFilters) => {
      const { family } = newFilters;
      setFilters(newFilters);
      if (family) {
        localStorage.setItem('family', family);
      }
    },
    [setFilters],
  );

  const handleSetSelectedFamily = useCallback(
    (newSelectedFamily) => {
      const { selectedFamily } = newSelectedFamily;
      setSelectedFamily(selectedFamily);
      if (selectedFamily) {
        localStorage.setItem('selectedFamily', JSON.stringify(selectedFamily));
      }
    },
    [setSelectedFamily],
  );

  return {
    filters,
    setFilters: handleSetFilters,
    selectedFamily,
    setSelectedFamily: handleSetSelectedFamily,
  };
};

export default useGlobalFilters;
