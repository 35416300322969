import { FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const RHFSelect = ({
  options,
  label,
  name,
  onChange,
  placeholder,
  errorText,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <Typography color="white" fontSize={'0.8rem'}>
            {label}
          </Typography>
          <Select
            fullWidth
            sx={{
              color: 'white',
              width: 300,
              '& > div': {
                caretColor: 'white',
                border: '1px solid #929eaa',
              },
              '& input': {
                color: 'white',
                '&::placeholder': { color: '#fff' },
              },
              '& svg': {
                color: 'white',
              },
              '&:hover': {
                border: '1px solid #929eaa',
              },
            }}
            error={!!error}
            {...field}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              field.onChange(e.target.value);
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {!!error ? (
            <FormHelperText error={!!error}>{errorText}</FormHelperText>
          ) : null}
        </div>
      )}
    />
  );
};

RHFSelect.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
};
RHFSelect.defaultProps = {
  onChange: () => null,
  errorText: 'This field is required',
};

export default RHFSelect;
