import { Box, Container, Pagination, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CatalogFilter from '../components/CatalogFilter';
import CatalogResults from '../components/CatalogResults';
import CatalogHeader from '../components/Header/CatalogHeader';
import LoadingIndicator from '../components/LoadingIndicator';
import Search from '../components/Search';
import useGlobalFilters from '../context/GlobalFilters/useGlobalFilters';
import useSearch from '../hooks/useSearch';
import { urls } from '../routes/routes';
import useCategories from './Categories/useCategories';
import useFamilies from './Families/useFamilies';

const Catalog = () => {
  const { filters, setFilters, selectedFamily, setSelectedFamily } =
    useGlobalFilters();

  const { data: families, isLoading: isLoadingFamilies } = useFamilies();
  const { data, setQuery, query, isLoading } = useSearch(filters);
  const { data: categories, isLoading: isLoadingCategories } =
    useCategories(filters);
  const navigate = useNavigate();

  useEffect(() => {
    if (!filters) {
      const family = localStorage.getItem('family');
      const selectedFamily = localStorage.getItem('selectedFamily');
      if (family) {
        setFilters({ family });
        setSelectedFamily({ selectedFamily: JSON.parse(selectedFamily) });
        setQuery({ ...query, family });
      } else {
        navigate(urls.family.user);
      }
    }
  }, [filters]);

  const handleSearch = useCallback(
    (e) => {
      setQuery({
        ...filters,
        limit: query.limit,
        skip: query.skip,
        search: e.target.value,
      });
    },
    [query, filters],
  );

  const onClickFilter = useCallback(
    (id) => {
      setQuery({ ...query, category: id, limit: 10, skip: 0 });
    },
    [query, setQuery],
  );

  const handlePageChange = useCallback(
    (e, page) => {
      setQuery({ ...filters, ...query, skip: query.limit * (page - 1) });
    },
    [setQuery, filters, query],
  );

  const backgroundImage = useMemo(
    () =>
      selectedFamily.cover
        ? `url('${
            process.env.REACT_APP_CLOUDINARY_URL + selectedFamily.cover
          }')`
        : "url('/catalog_background.png')",
    [selectedFamily, selectedFamily.cover],
  );

  if (isLoadingCategories) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingIndicator color={'primary'} size={40} />
      </Box>
    );
  }

  return (
    <div>
      <div
        style={{
          minHeight: '500px',
          backgroundImage: backgroundImage,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <CatalogHeader
          selectedFamily={selectedFamily}
          familiesLength={families.length}
        />
        <Box
          sx={(theme) => ({
            fontSize: '3.8em',
            alignSelf: 'flex-end',
            textAlign: 'center',
            color: 'white',
            fontWeight: 600,
            [theme.breakpoints.up('md')]: {
              pr: 6,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            },
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
            },
          })}
        >
          <Box
            component="span"
            sx={(theme) => ({
              fontFamily: 'sans-serif !important',
              [theme.breakpoints.down('md')]: {
                color: '#0e4722',
                textAlign: 'center',
              },
            })}
          >
            اكتشف منتجاتنا
          </Box>
          <Box
            component="span"
            sx={(theme) => ({
              fontFamily: 'sans-serif !important',
              [theme.breakpoints.down('md')]: {
                textAlign: 'center',
              },
            })}
          >
            و جميع أصنافنا
          </Box>
        </Box>
        <Search handleSearch={handleSearch} query={query} filters={filters} />
      </div>
      <Container
        sx={(theme) => ({
          display: 'flex',
          gap: 2,
          direction: 'rtl',
          [theme.breakpoints.up('md')]: {
            padding: 4,
            overflowX: 'auto',
            justifyContent: 'center',
          },
          [theme.breakpoints.down('md')]: {
            py: 4,
            gap: 2,
            overflowX: 'auto',
          },
        })}
      >
        {categories.map((category) => (
          <CatalogFilter
            key={category.id}
            name={category.row.nameAr}
            id={category.id}
            onClick={onClickFilter}
            filters={query}
          />
        ))}
      </Container>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          overflow: 'auto',
          paddingTop: 5,
        }}
      >
        <CatalogResults data={data} isLoading={isLoading} limit={query.limit} />

        <Stack py={2}>
          <Pagination
            count={isNaN(data.count) ? 0 : Math.ceil(data.count / query.limit)}
            onChange={handlePageChange}
          />
        </Stack>
      </Box>
    </div>
  );
};

export default Catalog;
